import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CardTooltip, Logo, ThemeToggle } from '../components';
import { Children } from '../utils';

export const FullScreen = ({ children, id }: { children: Children; id?: string }) => {
  return (
    <div id={id} className="bg-color flex h-screen w-screen flex-col items-center justify-center">
      {children}
    </div>
  );
};

export const FullPage = ({ children }: { children: Children }) => {
  return <div className="flex size-full flex-col items-center justify-center bg-transparent">{children}</div>;
};

export const Container = ({ children }: { children: Children }) => {
  return (
    <div className="relative flex h-fit max-w-full justify-center p-2 sm:p-4">
      <div className="flex w-full max-w-full flex-col lg:max-w-5xl">{children}</div>
    </div>
  );
};

export const Main = ({ title, children }: { title: string; children: Children }) => {
  return (
    <main className="relative h-full min-h-screen">
      <h1 className="sr-only">{title}</h1>
      {children}
    </main>
  );
};

export const AuthContainer = ({ header, children }: { header: string; children: Children }) => {
  return (
    <Container>
      <div className="absolute right-0 top-0 m-4">
        <ThemeToggle />
      </div>
      <div className="flex flex-col items-center py-16">
        <div className="relative h-24 px-4">
          <Logo type="color" className="self-end" href={import.meta.env.VITE_MARKETING_URL} />
        </div>
        <div className="bg-color-inverse mt-8 w-full max-w-sm rounded-xl px-4 py-8">
          <h1 className="text-color-inverse text-center text-3xl">{header}</h1>
          {children}
        </div>
      </div>
    </Container>
  );
};

export const AppLayout = ({ children }: { children: Children }) => {
  return <div className="relative h-full min-h-screen pb-24 pt-12 sm:pb-0 sm:pl-16 lg:pl-48 lg:pt-24">{children}</div>;
};

export const AuthLayout = ({ header, children }: { header: string; children: Children }) => {
  return (
    <Main title={header}>
      <AuthContainer header={header}>{children}</AuthContainer>
    </Main>
  );
};

export const FiltersContainer = ({ reset, children, id }: { reset: () => void; children: Children; id?: string }) => {
  return (
    <div id={id} className="flex flex-1 flex-col gap-4 sm:flex-row sm:items-center md:flex-row">
      {children}
      <button
        type="button"
        id="reset-filters"
        className="text-primary w-full cursor-pointer self-end p-2 text-center font-bold sm:w-auto"
        onClick={reset}
      >
        Reset
      </button>
    </div>
  );
};

export const Card = ({
  className,
  onClick,
  tooltipId,
  tooltipText,
  children,
}: {
  className?: string;
  onClick?: () => void;
  tooltipId?: string;
  tooltipText?: string;
  children: Children;
}) => {
  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        className={`${className} bg-light-dark dark:bg-dark-dark relative flex size-full max-w-[320px] cursor-pointer flex-col gap-4 rounded-[22px] p-4 shadow-[0_10px_10px_rgba(38,70,83,0.1)] lg:max-w-[420px]`}
      >
        {tooltipText && tooltipId && <CardTooltip text={tooltipText} id={tooltipId} />}
        {children}
      </button>
    );
  }
  return (
    <div
      className={`${className} ${
        onClick && 'cursor-pointer'
      } bg-light-dark dark:bg-dark-dark relative flex size-full max-w-[320px] flex-col gap-4 rounded-[22px] p-4 shadow-[0_10px_10px_rgba(38,70,83,0.1)] lg:max-w-[420px]`}
    >
      {tooltipText && tooltipId && <CardTooltip id={tooltipId} text={tooltipText} />}
      {children}
    </div>
  );
};

export const NavigationHandler = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
