import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, FilteredDatePicker, Loading, Modal, Select, TextInput } from '@epcbuilder/lib/components';
import { booleanOptions, slotBookingOptions } from '@epcbuilder/lib/models/generic';
import { JobSurvey } from '@epcbuilder/lib/models/jobs';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import useSurveyBookingAvailableSlots from '@/hooks/surveys/useSurveyBookingAvailableSlots';
import { SurveyBooking, SurveyBookingForm, SurveySaveBooking } from '@/models/job';
import { patchSurveyBooking, putSurveyBooking } from '@/network/surveys';

const JobSurveyModal = ({
  onClose,
  surveyBooking,
  survey,
}: {
  onClose: () => void;
  surveyBooking: SurveyBooking | undefined;
  survey: JobSurvey | undefined;
}) => {
  const defaultValues = {
    paid: surveyBooking?.paid || false,
    surveyDate: surveyBooking?.surveyDate ? new Date(surveyBooking?.surveyDate) : undefined,
    assessor: surveyBooking?.assessorName || '',
    bookingSlot: surveyBooking?.bookingSlot,
    notes: surveyBooking?.notes || '',
  };

  const {
    register,
    setError,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<SurveySaveBooking>({ defaultValues });

  const onSubmit: SubmitHandler<SurveySaveBooking> = async (data) => {
    try {
      if (surveyBooking) {
        if (data.surveyDate) {
          data.surveyDate = new Date(new Date(data.surveyDate).setHours(0, 0, 0, 0));
        }
        await patchSurveyBooking({ surveyBookingId: surveyBooking?.id, data });
      } else {
        if (survey && survey.id && data.surveyDate && data.bookingSlot) {
          const bookingDetails: SurveyBookingForm = {
            surveyId: survey!.id,
            surveyDate: new Date(surveyDate!),
            slot: data.bookingSlot!,
            state: 2,
            active: true,
            notes: data?.notes,
          };

          putSurveyBooking(bookingDetails);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error('Unable to create survey booking. Please ensure you have selected an available date and slot', {
            toastId: 'survey-booking-error',
          });
          return;
        }
      }
      toast.success('Survey booking updated', { toastId: 'survey-booking-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<SurveySaveBooking>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0);

  const maxDate = new Date();
  maxDate.setDate(today.getDate() + 28);
  maxDate.setHours(0, 0, 0);

  const slotAvailability = useSurveyBookingAvailableSlots({ start: today, end: maxDate }).availableSlots;
  const availableDays = slotAvailability?.filter((x) => x.available).map((x) => x.date);

  //If the current date would normally be available, allow it
  if (surveyBooking?.surveyDate && !availableDays?.includes(surveyBooking?.surveyDate))
    availableDays?.push(surveyBooking?.surveyDate);

  const daysToRemove = slotAvailability?.filter((x) => !availableDays?.includes(x.date)).map((x) => x.date);
  const [availableSlots, setAvailableSlots] = useState(slotBookingOptions);

  const surveyDate = watch('surveyDate');
  useEffect(() => {
    const specificDate = slotAvailability?.filter((x) => new Date(x.date).getDate() == surveyDate?.getDate());
    const am = specificDate?.find((x) => x.slot == 1);
    const pm = specificDate?.find((x) => x.slot == 2);

    setAvailableSlots(slotBookingOptions);

    if ((!am || !am.available) && (surveyBooking?.surveyDate != surveyDate || surveyBooking?.bookingSlot != 1)) {
      setAvailableSlots(slotBookingOptions.filter((x) => x.label == 'PM'));
      setValue('bookingSlot', 2);
    }
    if ((!pm || !pm.available) && (surveyBooking?.surveyDate != surveyDate || surveyBooking?.bookingSlot != 2)) {
      setAvailableSlots(slotBookingOptions.filter((x) => x.label == 'AM'));
      setValue('bookingSlot', 1);
    }
  }, [surveyDate, slotAvailability, setValue, surveyBooking?.bookingSlot, surveyBooking?.surveyDate]);

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <Modal id="job-pashub-modal" onClose={onClose}>
      <h1>Edit Survey Details</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-6 flex flex-col gap-4">
        <Select
          control={control}
          id="paid"
          name="paid"
          title="select paid"
          label="Paid"
          placeholder=""
          options={booleanOptions}
        />
        <div className="flex gap-2">
          <div className="w-3/5">
            <FilteredDatePicker
              daysToRemove={daysToRemove!}
              maxDate={maxDate}
              id="surveyDate"
              control={control}
              name="surveyDate"
              label="Selected booking date"
            />
          </div>
          <div className="w-2/5">
            <Select
              control={control}
              id="bookingSlot"
              name="bookingSlot"
              title="Select booking slot"
              label="Slot"
              placeholder=""
              options={availableSlots}
            />
          </div>
        </div>
        <TextInput
          className="bg-dark/10 dark:bg-light/80 cursor- cursor-not-allowed"
          disabled
          id="assessor"
          {...register('assessor')}
          label="Assessor"
          error={errors.assessor?.message}
        />
        <TextInput id="notes" {...register('notes')} label="Notes" error={errors.notes?.message} />
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="access-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default JobSurveyModal;
