import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';

const Pagination = <T extends FieldValues>({
  control,
  name,
  totalPages,
}: {
  control: Control<T>;
  name: Path<T>;
  totalPages: number;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        const currentPage = value;

        const renderPageNumbers = () => {
          const pageNumbers = [];
          pageNumbers.push(1);

          if (currentPage > 2) {
            pageNumbers.push('...');
          }

          if (currentPage > 1 && currentPage < totalPages) {
            pageNumbers.push(currentPage);
          }

          if (currentPage < totalPages - 1) {
            pageNumbers.push('...');
          }

          if (totalPages > 1) {
            pageNumbers.push(totalPages);
          }

          return pageNumbers.map((pageNumber, index) => (
            <button
              type="button"
              key={index}
              id={`pagination-${pageNumber}${pageNumber === currentPage ? '-active' : ''}`}
              className={`mx-1 cursor-pointer rounded p-1 ${
                pageNumber === '...' ? 'text-color font-bold' : pageNumber === currentPage ? 'text-color font-bold' : ''
              }`}
              onClick={() => pageNumber !== '...' && handleChangePage(pageNumber)}
            >
              {pageNumber}
            </button>
          ));
        };

        const handleChangePage = (pageNumber: string | number) => {
          if (pageNumber !== '...') {
            onChange(pageNumber);
          }
        };

        return (
          <div className="flex flex-row items-center">
            {currentPage > 1 && (
              <button
                type="button"
                id="pagination-previous"
                className="text-color mx-1 cursor-pointer rounded p-2"
                onClick={() => handleChangePage(currentPage - 1)}
              >
                <MdOutlineKeyboardArrowLeft />
              </button>
            )}
            {renderPageNumbers()}
            {currentPage < totalPages && (
              <button
                type="button"
                id="pagination-next"
                className="text-color mx-1 cursor-pointer rounded p-2"
                onClick={() => handleChangePage(currentPage + 1)}
              >
                <MdOutlineKeyboardArrowRight />
              </button>
            )}
          </div>
        );
      }}
    />
  );
};

export default Pagination;
